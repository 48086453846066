import { Box, Typography } from '@material-ui/core';
import { OrganizationType } from '../../types';

type Props = {
  organization: OrganizationType;
}

const About = ({ organization }: Props) => {
  if (!organization.descriptionOfOperations) {
    return null;
  }

  return (
    <Box maxWidth={600} mt={10} display="flex" flexDirection="column" alignItems="center">
      <Box mb={3}>
        <Typography variant="h4" align="center">About {organization.companyName}</Typography>
      </Box>
      <Typography variant="h6">
        {organization.descriptionOfOperations}
      </Typography>
    </Box>
  )
};

export default About;