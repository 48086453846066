import { Box, Button, AppBar, Toolbar, Typography, Container } from '@material-ui/core';
import { Link } from 'react-scroll';

type Props = {
  logo: string;
  companyName: string;
}

const Navigation = ({ logo, companyName }: Props) => {  
  return (
    <AppBar position="fixed" style={{ background: 'white' }}>
      <Container>
        <Toolbar>
          <Box
            flexGrow={1}
          >
            <Box py={2} height={60} display="flex" flexDirection="row" alignItems="center">
              <img src={logo} alt="company logo" height="100%" />
              <Box width={20}/>
              <Typography variant="h6" color="textPrimary">{companyName}</Typography>
            </Box>
          </Box>
          <Button size="large"><Link to="about" smooth={true} offset={-60} duration={500} >About</Link></Button>
          <Button size="large"><Link to="financing" smooth={true} offset={-50} duration={500} >Financing</Link></Button>
          <Button size="large"><Link to="contact" smooth={true} offset={50} duration={500} >Contact</Link></Button>
        </Toolbar>
      </Container>
    </AppBar>
  )
};

export default Navigation