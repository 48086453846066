import { Box, Typography, Button, Container } from '@material-ui/core';
import { useEffect } from 'react';
import { loadWidget } from '../../widget';

type Props = {
  urlPath: string;
};

const Financing = ({ urlPath }: Props) => {
  useEffect(() => {
    loadWidget();
  }, []);
  // https://master.app.gethearth.com/partners/hearth-demo/john/apply
  const financingLink = `https://master.app.gethearth.com/partners/${urlPath}/`

  return (
    <Container>
      <Box display="flex" flexDirection="row" alignItems="start" mt={5}>
        <Box p={10}>
          <Box mb={3}>
            <Typography variant="h3">Financing</Typography>
          </Box>
          <Typography variant="h6" style={{ marginBottom: '500' }}>We offer our clients easy monthly payment options with Hearth.</Typography>
          <br/>
          <Typography variant="h6">Use the calculator on the right to estimate the monthly payments for your next project.</Typography>
          <br/>
          <br/><br/>
          <Typography variant="h6">Get prequalified for your next project without affecting your credit score: </Typography>
          <br/>
          <br/>
          <Button size="large" href={financingLink} target="_blank" variant="outlined">Get prequalified now</Button>
        </Box>
        <Box>
          <iframe title="calculator" id="hearth-widget_calculator_v1"></iframe>
        </Box>
      </Box>
    </Container>
  )
};

export default Financing;