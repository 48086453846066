import { Box, Typography, Button, Container } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Link } from 'react-scroll';


type Props = {
  organizationName: string;
  organizationArea: string;
  backgroundPicture: string;
};

const Header = ({ organizationName, organizationArea, backgroundPicture }: Props) => {
  const defaultPicture = 'https://images.unsplash.com/photo-1513467535987-fd81bc7d62f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1190&q=80';

  return (
    <Container>
      <Box
        height={600}
        width="40%"
        my={5}
        display="inline-block"
      >
        <Box mt={20} height={200} display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h2">
            {organizationName}
          </Typography>
          <Box display="flex" alignItems="center" mb={3}>
            <LocationOnIcon />
            <Typography variant="h6">{organizationArea}</Typography>
          </Box>
          <Button size="large" variant="contained" color="primary">
            <Link to="contact" smooth={true} offset={50} duration={500} >Contact</Link>
          </Button>
        </Box>
      </Box>
      <Box
        height={600}
        display="inline-block"
        width="60%"
        my={3}
        style={{
          background: backgroundPicture.startsWith('https') ? `url(${backgroundPicture}) no-repeat center center` : `url(${defaultPicture}) no-repeat center center`,
          backgroundSize: "cover",
          verticalAlign: "top",
        }}
      >
        <svg height="600" width="200">
          <polygon points="0,0 0,600 200,0" style={{ fill: 'white' }} />
          Sorry, your browser does not support inline SVG.
        </svg>
      </Box>
    </Container>
  )
};

export default Header;