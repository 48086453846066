import { Box, Typography } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';

import ContactRow from './ContactRow';

type Props = {
  organizationName: string;
  organizationAddress: string;
}

const Contact = ({ organizationName, organizationAddress }: Props) => {

  const cardBackgroundColor = blueGrey['50'];

  return (
    <Box height={400} m={10}>
      <Box mb={3}>
        <Typography variant="h4" align="center">Contact {organizationName}</Typography>
      </Box>
      <Box
        bgcolor={cardBackgroundColor}
        height={250}
        width={500}
        p={5}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <ContactRow rowType="Email" data="email@gmail.com" />
        <ContactRow rowType="Phone" data="876-3020-9191" />
        <ContactRow rowType="Address" data={organizationAddress} />
      </Box>
    </Box>
  )
};

export default Contact;