import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import PinDropIcon from '@material-ui/icons/PinDrop';
import { Grid, Box, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

type Props = {
  rowType: string;
  data: string;
};

const ContactRow = ({ rowType, data }: Props) => {

  const iconColor = grey['500'];

  return (
    <Box paddingLeft={5} my={2}>
      <Grid container>
        <Grid item container direction="row" alignItems="center">
          <Grid item xs={2}>
            {rowType==="Email" && <MailOutlineIcon fontSize="large" style={{ color: iconColor }} />}
            {rowType==="Phone" && <PhoneIcon fontSize="large" style={{ color: iconColor }} />}
            {rowType==="Address" && <PinDropIcon fontSize="large" style={{ color: iconColor }} />}
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body1">Business {rowType}</Typography>
            <Typography variant="h6">{data}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
};

export default ContactRow;