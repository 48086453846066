import ky from 'ky';
import { camelizeKeys } from 'humps';
import { useState, useEffect } from 'react';

import WebsiteTemplate from './WebsiteTemplate';
import { Maybe, OrganizationType } from './types';

type OrganizationWrapper = {
  organization: OrganizationType;
}

function App() {
  const [organization, setOrganization] = useState<Maybe<OrganizationType>>(null);

  async function getOrganization(urlPath: string) {
    // go to hearth-demo.localhost:3000 for this to work
    // const urlPath = window.location.pathname.substring(1);
    const url = `https://staging-hearth-api.shogun.cool/api/v2/organizations/website?url_path=${urlPath}`;
    const json: OrganizationWrapper = await ky.get(url).json();
    const org = camelizeKeys(json.organization) as OrganizationType;
    setOrganization(org);
  }

  useEffect(() => {
    const hostname = window.location.hostname;
    const urlPath = hostname.substring(0, hostname.indexOf('.'));
    if (urlPath === 'www' || urlPath.length === 0) {
      // if www.hearthwebsites.com -> redirect to gethearth.com
      window.location.assign('https://www.gethearth.com/');
    } else {
      getOrganization(urlPath);
    }
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {organization &&
          <WebsiteTemplate organization={organization} />
        }
      </header>
    </div>
  );
}

export default App;
