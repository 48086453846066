import { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Element } from 'react-scroll';
import { OrganizationType } from '../types';

import {
  Navigation,
  Header,
  Financing,
  Contact,
  Footer,
  About,
} from './components';

type Props = {
  organization: OrganizationType;
}

// TODO:
// - Colors -> can we use the UI kitten colors?
// - About.tsx is not fininished

const WebsiteTemplate = ({
  organization,
}: Props): JSX.Element => {
  useEffect(() => {
    document.title = organization.companyName;
  })

  return (
    <Box>
      <Navigation logo={organization.logoUrl} companyName={organization.companyName}/>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={3}
        mt={8}
      >
        <Header
          organizationName={organization.companyName}
          organizationArea={organization.generalArea}
          backgroundPicture={organization.backgroundPicture}
        />
        <Element name="about" className="element">
          <About organization={organization} />
        </Element>
        <Element name="financing" className="element">
          <Financing urlPath={organization.urlPath} />
        </Element>
        <Element name="contact" className="element">
          <Contact organizationName={organization.companyName} organizationAddress={organization.fullAddress}/>
        </Element>
        <Footer />
      </Box>
    </Box>
  );
};


export default WebsiteTemplate;