export function loadWidget() {
  const banners = document.querySelectorAll('[id^=hearth-banner_]');

  // const script = document.getElementById('hearth-script');
  let orgId, partnerPath;
  // if (script) {
  //   orgId = script.dataset.orgid || '';
  //   partnerPath = script.dataset.partner || '';
  // }
  orgId = 564;
  partnerPath = "hearth-demo";

  if (!orgId || !partnerPath){
    return;
  }

  for (const element of banners) {
    if (element) {
      const [ banner, color ] = element.id.replace('hearth-banner_', '').split(/_(.+)/);
      const base = process.env.BASE_URL;
      const [ width, height ] = banner.split('x');
      const utms = `utm_campaign=${orgId}&utm_content=${color}&utm_medium=contractor-website&utm_source=contractor&utm_term=${banner}`;
      const link = `${base}/partners/${partnerPath}?${utms}`;

      const src = `${base}/contractor_images/${partnerPath}/banner.jpg?color=${color}&size_id=${banner}`;
      const alt = `hearth financing ${banner}`;
      const style = `height:${height}px;width:${width}px`;
      const code =
        `<a href="${link}"target="_blank">` +
          `<img src="${src}"alt="${alt}"style="${style}"/>` +
        `</a>`;
      element.innerHTML = code;
    }
  }

  const widgets = document.querySelectorAll('[id^=hearth-widget_]');

  for (const widget of widgets) {
    if (widget) {
      const base = process.env.BASE_URL;
      const utms = `utm_campaign=${orgId}&utm_content=calculator&utm_medium=widget-calculator&utm_source=contractor&utm_term=widget`;
      const link = `${base}/partners/${partnerPath}?${utms}`;
      const style = `
        body {
          margin: 0;
          font-family: 'Poppins', sans-serif;
          -webkit-font-smoothing: antialiased;
        }
        a {
          text-decoration: none;
          color: white;
        }
        h2, h3, h4 {
          margin: 0;
        }
        h2 {
          font-size: 20px;
          line-height: 36px;
        }
        h3 {
          font-size: 28px;
          line-height: 40px;
        }
        h4 {
          font-weight: 600;
        }
        select::-ms-expand {
          display: none;
        }
        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: '';
        }
        .hrth-container {
          background-color: #F4F6F9;
          width: 375px;
          height: 934px;
          border-radius: 4px;
          overflow: hidden;
          position: relative;
        }
        .hrth-header {
          padding: 32px 16px 8px;
          text-align: center;
        }
        .hrth-body {
          padding: 16px;
          position: relative;
        }
        label {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 9px;
          display: block;
        }
        input, select {
          display: block;
          background: white;
          border: 1px solid #DBE0E4;
          border-radius: 2px;
          margin-bottom: 22px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          padding: 12px;
          width: 100%;
        }
        .error {
          color: #FF5345;
          position: absolute;
          margin-top: -20px;
        }
        .hrth-result {
          background: #00B971;
          border-radius: 4px;
          padding: 24px;
          text-align: center;
          display: block;
          margin: 24px 0 12px;
        }
        small {
          font-size: 10px;
          line-height: 16px;
          color: #58595B;
        }
        table {
          width: 100%;
          border-spacing: 0;
          margin: 20px 0;
        }
        table tr td {
          border-bottom: 1px solid #DBE0E4;
          padding: 8px 0;
        }
        table tr:last-child td {
          border-bottom: 0;
        }
        .cta-container {
          text-align: center;
          margin-top: 24px;
        }
        .cta {
          background: #1A73E8;
          border-radius: 4px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          padding: 12px 33px;
          display: inline-block;
        }
        .hrth-footer {
          background: #1C5AA6;
          padding: 24px 0;
          text-align: center;
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      `;

      const code = `
        <head>
          <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap" rel="stylesheet">
          <style>${style}</style>
        </head>
        <body>
          <div class="hrth-container">
            <div class="hrth-header">
              <h2>Project financing calculator</h2>
            </div>
            <div class="hrth-body">
              <div class="hrth-inputs">
                <label for="hrth-amount">Financing needed</label>
                <input type="text" id="amount" placeholder="$12,000"/>
                <small class="error" id="error" style="display:none">Please choose an amount between $1,000 and $100,000.</small>
                <label for="hrth-credit">Your credit score</label>
                <select name="hrth-credit" id="credit" onchange="recalculate()">
                  <option value="0.10489999999999999">Excellent (741 - 850)</option>
                  <option value="0.19370000000000001">Good (681 - 740)</option>
                  <option value="0.2359">Average (661 - 680)</option>
                  <option value="0.25819999999999999">Poor (500 - 660)</option>
                </select>
              </div>
              <a class="hrth-result" href="${link}" target="_blank">
                Estimated monthly payment
                <h3 id="monthly">$257.87</h3>
              </a>
              <small>
                The estimated monthly payment is provided for information purposes
                only. It is based on the user's selected loan amount and the credit
                score range; and calculated using a 5 year loan term with 60 monthly
                payments and an APR of
                <span id="terms-apr">10.49</span>%.
                The estimate does not account for missed payments, the use of deferment
                or forbearance.
              </small>
              <table>
                <tr>
                  <td>Principal</td>
                  <td style="text-align:right" id="principal">$12,000.00</td>
                </tr>
                <tr>
                  <td>Interest</td>
                  <td style="text-align:right" id="interest">$3,472.04</td>
                </tr>
                <tr>
                  <td style="font-weight:600">Total Loan Cost Estimate</td>
                  <td style="text-align:right;font-weight:600" id="total">$15,472.04</td>
                </tr>
              </table>
              <h4>Get your personalized rates:</h4>
              <p>This takes two minutes and does not affect your credit score.</p>
              <div class="cta-container">
                <a class="cta" href="${link}" target="_blank">Get Financing Options</a>
              </div>
            </div>
            <div class="hrth-footer">
              <img src="https://hearth.imgix.net/logos/hearth-white-2020.svg" />
            </div>
          </div>
          <img src="${base}/pixel.png?organization_id=${orgId}&type=widget-calculator" />

          <script>
            const recalculate = function () {
              const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              });

              const e = document.getElementById("credit");
              const apr = e.options[e.selectedIndex].value;

              const principal = document.getElementById("amount").value.replace(/[^0-9.]/g,'') || 12000;
              const loanTerm = 60;

              const monthlyApr = apr / 12;

              const monthlyPow = ((1 + monthlyApr) ** loanTerm);
              const monthlyPayment = monthlyPow === 1 ? (principal / loanTerm) :
                (principal * monthlyApr * monthlyPow) / (monthlyPow - 1);

              const total = loanTerm * monthlyPayment;
              const interest = total - principal;

              document.getElementById("monthly").innerHTML = formatter.format(monthlyPayment);
              document.getElementById("terms-apr").innerHTML = (apr*100).toFixed(2);
              document.getElementById("principal").innerHTML = formatter.format(principal);
              document.getElementById("interest").innerHTML = formatter.format(interest);
              document.getElementById("total").innerHTML = formatter.format(total);
            }

            document.getElementById('amount').addEventListener("change", function () {
              const e = document.getElementById("amount");
              const error = document.getElementById("error");
              const value = e.value.replace(/[^0-9.]/g,'');
              if (e && (value < 1000 || value > 100000)) {
                error.style.display = "block";
              } else {
                error.style.display = "none";
              }
            });
          </script>
        </body>
      `;
      widget.frameBorder = 0;
      widget.frameSpacing = 0;
      widget.width = 375;
      widget.height = 934;
      widget.scrolling = "no";
      widget.contentDocument.write(code);
      widget.contentDocument.close();
    }
  }
}
